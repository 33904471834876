import React, { createContext, useReducer } from "react";
import appReducer from "../reducers/app-reducer";
import { useMessages } from "../../hooks/useMessages";
import { isBrowser } from "framer-motion";

export const appContext = createContext();

const AppProvider = appContext.Provider;

export function AppWrapper({ children }) {
  let messages = useMessages();

  if (isBrowser && messages) {
    messages = messages.filter(msg => !localStorage.getItem(msg.updatedAt));
  }
  const [app, setApp] = useReducer(appReducer, {
    alert: messages,
  });
  return <AppProvider value={{ app, setApp }}>{children}</AppProvider>;
}
