import { createTheme } from "@mui/material";

const headerStyles = {
  fontFamily: "Archia, serif",
  fontWeight: "bold",
  textAlign: "center",
};

let theme = createTheme({
  mobileMenu: {
    width: "300px",
  },
  spacing: [0, 4, 8, 12, 16, 20, 24, 32, 40, 56, 64, 72, 96, 128, 160].map(
    px => `${px / 16}rem`
  ),
  palette: {
    primary: {
      main: "#33cc99",
    },
    grey0: "#f6f9f9",
    grey1: "#F2F5F5",
    grey2: "#E1E6E6",
    grey3: "#CDD4D4",
    grey4: "#A0A8A8",
    grey5: "#868F8F",
    grey6: "#4D5454",
    white: "#fff",
    black: "#000000",
  },
  // Typography
  typography: {
    fontWeightRegular: 400,
    fontFamily: "Open Sans, sans-serif",
    h1: {
      ...headerStyles,
      fontSize: "var(--fs-8)",
    },
    h2: {
      ...headerStyles,
      fontSize: "var(--fs-7)",
    },
    h3: {
      ...headerStyles,
      fontSize: "var(--fs-6)",
    },
    body1: {
      lineHeight: 1.65,
    },
  },
  // Breakpoint for responsive design
  breakpoints: {
    values: {
      xs: 0,
      sm: 520,
      md: 768,
      lg: 960,
      xl: 1280,
      xxl: 1650,
      xxxl: 1920,
    },
  },
  transitions: {
    easing: {
      easeOutBack: "cubic-bezier(0.34, 1.56, 0.64, 1)",
      easeOutCubic: "cubic-bezier(0.33, 1, 0.68, 1)",
      easeOutQuint: "cubic-bezier(0.22, 1, 0.36, 1)",
      slide: "cubic-bezier(0.4, 0, 0.2, 1)",
    },
    duration: {
      enteringScreen: 350,
      leavingScreen: 350,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          boxShadow: "none",
          ...(ownerState.variant === "contained" && {
            // Default styles for all buttons
            transition: "all 0.2s ease-in",
            border: "none",
            borderRadius: 0,
            padding: "1em 2em",
            color: "var(--white)",
            fontWeight: 700,
            letterSpacing: 1,
            // backgroundColor: "var(--primary-color-light)",

            "&:hover": {
              border: "none",
              backgroundColor: "var(--primary-color-dark)",
              borderRadius: 0,
              boxShadow: "none",
            },
          }),
        }),
      },
    },
  },
});

// console.log(theme)

export default theme;
