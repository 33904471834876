/**
 * Main wrapper for gatsby-browser.js and gatsby-ssr.js
 */
import React from 'react';
import { AppWrapper } from './src/contexts/wrappers/app-wrapper';

export default function RootWrapper({ element }) {
  return (
    <AppWrapper>
      {element}
    </AppWrapper>
  )
}
