import { graphql, useStaticQuery } from "gatsby";

export const useMessages = locale => {
  const messages = useStaticQuery(graphql`
    query {
      allStrapiMessage(filter: { enable: { eq: true } }) {
        nodes {
          locale
          updatedAt
          id
          title
          enable
          content {
            data {
              content
            }
          }
        }
      }
    }
  `);
  return messages.allStrapiMessage.nodes.length === 0
    ? false
    : messages.allStrapiMessage.nodes;
};
