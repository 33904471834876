import { 
  CLOSE_ALERT
 } from "../actions/action-types";
 import { isBrowser } from "framer-motion";

export default function appReducer(state, action){
  switch (action.type) {
    case CLOSE_ALERT:
      isBrowser && localStorage.setItem(action.payload.id, true)
      return {
        ...state,
        alert: false
      }
    default:
      return state
  }
}